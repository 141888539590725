
$grid-columns: 																			24;
@import "bootstrap/scss/bootstrap";

html, body {
	margin: 0;
	padding: 0;
	font-size: 16px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Poppins, sans-serif; }

@import "styles/global.colors";
@import "styles/global.fonts";
@import "styles/global.forms";
@import "styles/global.layout";

@import "styles/theme.rearview";