// Rearview purple
// "#5e36dd"

// Rearview blue
// 071956
	// alternatives
	// 1D2C63
	// 7,26,86

// Rearview Gray
// EDEFF7

$color-rv-purple: #5e36dd;
$color-rv-purple-lt-1: #A0A0B4;
.bg-color--brand-purple { background-color: $color-rv-purple; }
.bg-color--purple-gradient, // do not use `bg-color--purple-gradient`.  this is here for ui-page demo/example.
.bg-color--gradient-purple {
	background: linear-gradient(166.64deg, #7C00DE -14.24%, rgba(124, 0, 222, 0) 185.18%);
}
.font-color--brand-purple { color: $color-rv-purple; }


$color-rv-blue: #071956;
$color-rv-blue-lt-5: #394677;
$color-rv-blue-dk-1: #454559;
.bg-color--brand-blue { background-color: $color-rv-blue; }
.bg-color--blue-gradient,
.bg-color--gradient-blue {
	background: linear-gradient(166.64deg, #0904b3 -14.24%, rgba(9, 4, 179, 0) 185.18%);
}
.font-color--brand-blue { color: $color-rv-blue; }

$color-rv-gray-lt-2: #F7F8FA;
$color-rv-gray: #EDEFF7;
$color-rv-gray-dk-1: #CBCED7;
$color-rv-gray-dk-2: #A4A6AE;
$color-rv-gray-dk-3: #898B93;
$color-rv-gray-dk-4: #6E7077;
$color-rv-gray-dk-5: #414247;

$color-validation-success: #4caf50;
$color-validation-success-bg: #bdffbf;
$color-validation-error: #f44336;
$color-validation-error-bg: #ffc8c4;

$color-purple-900: #170B3F;
$color-purple-800: #251166;
$color-purple-700: #32168A;
$color-purple-600: #431FB8;
$color-purple-500: $color-rv-purple;
$color-purple-400: #7755E1;
$color-purple-300: #9D89DC;
$color-purple-200: #BFB5DC;
$color-purple-100: #D9D7DF;
$color-blue-900: #131D43;
$color-blue-800: #1D2C63;
$color-blue-700: #343F68;
$color-blue-600: #3E486F;
$color-grey-900: #3F3F3F;
$color-grey-800: #565656;
$color-grey-700: #6C6C6C;
$color-grey-600: #868585;
$color-grey-500: #A1A1A1;
$color-grey-400: #C3C3C3;
$color-grey-300: #DADADA;
$color-grey-200: #E2E2E2;
$color-grey-100: #FAFAFA;
$color-success: #179C0B;
$color-warning: #E58F0C;
$color-error: #C03A4A;
$color-white: #FFF;
$color-off-white: #FAFAFA;
$color-black: #000;
$color-shadowed: rgba(0,0,0,0.35);

.bg-color--purple-900 { background-color: $color-purple-900; }
.bg-color--purple-800 { background-color: $color-purple-800; }
.bg-color--purple-700 { background-color: $color-purple-700; }
.bg-color--purple-600 { background-color: $color-purple-600; }
.bg-color--purple-500 { background-color: $color-purple-500; }
.bg-color--purple-400 { background-color: $color-purple-400; }
.bg-color--purple-300 { background-color: $color-purple-300; }
.bg-color--purple-200 { background-color: $color-purple-200; }
.bg-color--purple-100 { background-color: $color-purple-100; }
.bg-color--blue-900 { background-color: $color-blue-900; }
.bg-color--blue-800 { background-color: $color-blue-800; }
.bg-color--blue-700 { background-color: $color-blue-700; }
.bg-color--blue-600 { background-color: $color-blue-600; }
.bg-color--grey-900 { background-color: $color-grey-900; }
.bg-color--grey-800 { background-color: $color-grey-800; }
.bg-color--grey-700 { background-color: $color-grey-700; }
.bg-color--grey-600 { background-color: $color-grey-600; }
.bg-color--grey-500 { background-color: $color-grey-500; }
.bg-color--grey-400 { background-color: $color-grey-400; }
.bg-color--grey-300 { background-color: $color-grey-300; }
.bg-color--grey-200 { background-color: $color-grey-200; }
.bg-color--grey-100 { background-color: $color-grey-100; }
.bg-color--success { background-color: $color-success; }
.bg-color--warning { background-color: $color-warning; }
.bg-color--error { background-color: $color-error; }
.bg-color--white { background-color: $color-white; }

.font-color--purple-900 { color: $color-purple-900; }
.font-color--purple-800 { color: $color-purple-800; }
.font-color--purple-700 { color: $color-purple-700; }
.font-color--purple-600 { color: $color-purple-600; }
.font-color--purple-500 { color: $color-purple-500; }
.font-color--purple-400 { color: $color-purple-400; }
.font-color--purple-300 { color: $color-purple-300; }
.font-color--purple-200 { color: $color-purple-200; }
.font-color--purple-100 { color: $color-purple-100; }
.font-color--blue-900 { color: $color-blue-900; }
.font-color--blue-800 { color: $color-blue-800; }
.font-color--blue-700 { color: $color-blue-700; }
.font-color--blue-600 { color: $color-blue-600; }
.font-color--grey-900 { color: $color-grey-900; }
.font-color--grey-800 { color: $color-grey-800; }
.font-color--grey-700 { color: $color-grey-700; }
.font-color--grey-600 { color: $color-grey-600; }
.font-color--grey-500 { color: $color-grey-500; }
.font-color--grey-400 { color: $color-grey-400; }
.font-color--grey-300 { color: $color-grey-300; }
.font-color--grey-200 { color: $color-grey-200; }
.font-color--grey-100 { color: $color-grey-100; }
.font-color--success { color: $color-success; }
.font-color--warning { color: $color-warning; }
.font-color--error { color: $color-error; }

:export {
	colorPurple900: $color-purple-900;
	colorPurple800: $color-purple-800;
	colorPurple700: $color-purple-700;
	colorPurple600: $color-purple-600;
	colorPurple500: $color-purple-500;
	colorPurple400: $color-purple-400;
	colorPurple300: $color-purple-300;
	colorPurple200: $color-purple-200;
	colorPurple100: $color-purple-100;
	colorBlue900: $color-blue-900;
	colorBlue800: $color-blue-800;
	colorBlue700: $color-blue-700;
	colorBlue600: $color-blue-600;
	colorGrey900: $color-grey-900;
	colorGrey800: $color-grey-800;
	colorGrey700: $color-grey-700;
	colorGrey600: $color-grey-600;
	colorGrey500: $color-grey-500;
	colorGrey400: $color-grey-400;
	colorGrey300: $color-grey-300;
	colorGrey200: $color-grey-200;
	colorGrey100: $color-grey-100;
	colorSuccess: $color-success;
	colorWarning: $color-warning;
	colorError: $color-error;
	colorWhite: $color-white;
}
