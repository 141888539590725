@import "./global.colors";

@font-face {
 font-family: "rv-icon";
 src: url("https://cdn.rearview.dev/fonts/rv-icons/icomoon.woff") format("woff"),
 url("https://cdn.rearview.dev/fonts/rv-icons/icomoon.svg#filename") format("svg");
}

.font-face--poppins {
	font-family: 'Poppins', sans-serif;
}

.font-face--nunito {
	font-family: 'Nunito', sans-serif;
}

.font-face--nunito-sans {
	font-family: 'Nunito Sans', sans-serif;
}

.font-face--roboto {
	font-family: 'Roboto', sans-serif;
}

.font-face--rouge {
	font-family: 'Rouge Script', sans-serif;
}

.font-color--purple {
	color: $color-rv-purple;
}



.font-color--white {
	color: #FFF;
}

.font-color--black {
	color: #000;
}

.font-color--purple-lt-1 {
	color: $color-rv-purple-lt-1;
}

.font-color--blue {
	color: $color-rv-blue;
}

.font-color--blue-dk-1 {
	color: $color-rv-blue-dk-1;
}

.font-color--gray {
	color: $color-rv-gray;
}

.font-color--dk-gray {
	color: $color-rv-gray-dk-1;
}

.font-color--dk-gray-2 {
	color: $color-rv-gray-dk-2;
}

.font-color--dk-gray-3 {
	color: $color-rv-gray-dk-3;
}

.font-color--dk-gray-4 {
	color: $color-rv-gray-dk-4;
}

.font-color--dk-gray-5 {
	color: $color-rv-gray-dk-5;
}

.rv-heading-xl {
	@extend .font-face--nunito;
	font-weight: 600;
	font-size: 3rem;
	line-height: 3.5rem;
}

.rv-heading-lg {
	@extend .font-face--nunito;
	font-weight: 600;
	font-size: 2.5rem;
	line-height: 3rem;
}

.rv-heading-md {
	@extend .font-face--nunito;
	font-weight: 600;
	font-size: 2rem;
	line-height: 2.5rem;
}

.rv-heading-sm {
	@extend .font-face--nunito;
	font-weight: 600;
	font-size: 1.5rem;
	line-height: 2rem;
}

.rv-heading-xs {
	@extend .font-face--nunito;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.5rem;
}



.rv-copy {
	@extend .font-face--poppins;
	letter-spacing: 0;

	&-14 {
		font-size: 14px;
		line-height: 135%;
	}
	&-16 {
		font-size: 16px;
		line-height: 135%;
	}
	&-16-bold {
		font-size: 14px;
		line-height: 120%;
		font-weight: 600;
	}
	&-20 {
		font-size: 20px;
		line-height: 135%;
	}
	&-24 {
		font-size: 24px;
		line-height: 135%;
	}
	&-stmt {
		font-size: 1.25rem;
		line-height: 2.25rem;
	}
}




h1,h2,h3,h4,h5,h6 {
	font-family: 'Poppins';
	font-weight: 500;
}

h1 {
	font-size: 48px;
	line-height: 120%;
}
h2 {
	font-size: 40px;
	line-height: 120%;
}
h3 {
	font-size: 32px;
	line-height: 115%;
}
h4 {
	font-size: 28px;
	line-height: 115%;
}
h5 {
	font-size: 24px;
	line-height: 115%;
}
h6 {
	font-size: 20px;
	line-height: 115%;
}


.text--underline {
	border-bottom: 1px solid $color-grey-400;
}

.text--ellipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}