@mixin rvForm {

	.form-group {
		position: relative;
		border: none;

		input.form-control {
			color: $color-rv-blue;
			border-radius: 0;
			border: none;
			text-align: center;
			padding-top: 10px;
			padding-bottom: 10px;

			&::placeholder {
				color: $color-rv-blue;
				font-size: 0.8rem;
			}

			&:focus,
			&:active {
				outline: none;
				box-shadow: none;
			}
		}
	}

	button.btn-spinner {
		@keyframes spinner {
			to {transform: rotate(360deg);}
		}

		.btn-label {
			transition: opacity 0.3s ease-in-out;
			opacity: 1;
		}

		&.spin {
			.btn-label {
				opacity: 0;
			}
		}

		&.spin:before {
			content: '';
			box-sizing: border-box;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 20px;
			height: 20px;
			margin-top: -10px;
			margin-left: -10px;
			border-radius: 50%;
			border: 2px solid #ffffff;
			border-top-color: #000000;
			animation: spinner .8s linear infinite;
		}
	}
}

.rv-form {
	@include rvForm();
}

.rv-form-bg-blue {
	
}