.page-container-fluid {
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	padding-top: 4rem;
	overflow-y: auto;

	@media screen and (min-width: 576px) {
		// border: thin solid yellow;
	}

	@media screen and (min-width: 768px) {
		// border: thin solid aqua;
	}

	@media screen and (min-width: 992px) {
		// border: thin solid green;
	}

	@media screen and (min-width: 1200px) {
		// border: thin solid red;
	}

	@media screen and (min-width: 1300px) {
		// border: thin solid Chartreuse;
	}

	@media screen and (min-width: 1400px) {
		// border: thin solid black;
	}
}

.full-page-container-fluid {
	@extend .page-container-fluid;
	margin: 0;
	padding: 5rem 0 0 0;

	.full-page-inner-container {
		width: 100%;

		&.expands {
			display: flex;
			flex: 1;
			flex-grow: 1;
			flex-wrap: wrap;

			&>.container-fluid {
				flex: 1;
				flex-basis: 100%;
			}
		}
	}
}